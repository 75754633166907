import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { IEmployee } from '../../core/models';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public user: IEmployee;
  public confirmationExpiryDateLocalized: string;
  @ViewChild('drawer') public drawer: MatSidenav;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private router: Router
  ) {
    router.events
      .pipe(
        withLatestFrom(this.isHandset$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe((_) => this.drawer && this.drawer.close());
  }

  get userName(): string {
    if (this.user) {
      return this.user.first_name + ' ' + this.user.last_name;
    }
    return '';
  }

  get salonName(): string {
    const selectedSalon = JSON.parse(localStorage.getItem('selectedSalon'));
    if (selectedSalon) {
      return selectedSalon.name;
    }
    return '';
  }

  public ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('loggedInUser'));
    if (!this.user.is_confirmed) {
      const datePipe: DatePipe = new DatePipe(this.translate.currentLang);
      this.confirmationExpiryDateLocalized = datePipe.transform(this.user.confirmation_expiry_date, 'short');
    }
  }

  public logout(): void {
    this.oauthService.logOut();
    this.router.navigate(['/']);
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('loggedInUserId');
    localStorage.removeItem('loggedInUserFirstName');
    localStorage.removeItem('loggedInUserEmail');
    localStorage.removeItem('loggedInUserLastName');
  }
}
